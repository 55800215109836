(function (define) {
  define(['jquery'], function ($) {
    return function () {
      var services = {
        main: main
      };
      return services;
      function main(){
        $(document).ready(function(){
          $('.owl-banner').owlCarousel({
            loop:true,
            margin:24,
            dots: true,
            responsiveClass:true,
            responsive:{
                0:{
                  items:1,
                  nav:true
                },
                600:{
                  items:2,
                  nav:true
                },
                1000:{
                  items:3,
                  nav:true,
                  loop:false
                }
            },
            navText: ['<i class="c-icon c-carousel--prev"></i>', '<i class="c-icon c-carousel--next"></i>']
          })
          $('.owl-posts').owlCarousel({
            loop:true,
            margin: 41,
            responsiveClass:true,
            dots: false,
            responsive:{
                0:{
                  items:1,
                  nav:true
                },
                600:{
                  items:2,
                  nav:true
                },
                1000:{
                  items:3,
                  nav:false,
                  loop:false
                }
            },
            navText: ['<i class="c-icon c-carousel--prev"></i>', '<i class="c-icon c-carousel--next"></i>']
          });

          $('.owl-writer').owlCarousel({
            loop:true,
            margin: 16,
            responsiveClass:true,
            dots: false,
            responsive:{
                0:{
                  items:1,
                  nav:true,
                  dots: true,
                },
                600:{
                  items:1,
                  nav:true,
                  dots: true,
                },
                1000:{
                  items:4,
                  nav:false,
                  loop:false
                }
            },
            navText: ['<i class="c-icon c-carousel--prev"></i>', '<i class="c-icon c-carousel--next"></i>']
          });
          $('.owl-recruit').owlCarousel({
            loop:true,
            margin: 16,
            responsiveClass:true,
            dots: true,
            responsive:{
                0:{
                  items:1,
                  nav:true
                },
                600:{
                  items: 2,
                  nav:true
                },
                1000:{
                  items: 3,
                  nav:true,
                  loop:true
                }
            },
            navText: ['<i class="c-icon c-carousel--prev"></i>', '<i class="c-icon c-carousel--next"></i>']
          });
          $('.owl-partner').owlCarousel({
            loop:true,
            margin: 16,
            responsiveClass:true,
            dots: false,
            responsive:{
                0:{
                  items:1,
                  nav:true,
                  dots: true,
                },
                600:{
                  items:2,
                  nav:true
                },
                1000:{
                  items:3,
                  nav:false,
                  loop:false
                }
            },
            navText: ['<i class="c-icon c-carousel--prev"></i>', '<i class="c-icon c-carousel--next"></i>']
          });
          $('.owl-case').owlCarousel({
            loop:true,
            margin: 9,
            responsiveClass:true,
            dots: true,
            responsive:{
                0:{
                  items: 1,
                  slideBy: 1,
                  autoWidth:true,
                  nav:true,
                  dots: true,
                },
                600:{
                  margin: 13,
                  items: 1,
                  slideBy: 1,
                  autoWidth:true,
                  nav:true,
                  dots: true,
                },
            },
            navText: ['<i class="c-icon c-carousel--prev"></i>', '<i class="c-icon c-carousel--next"></i>']
          });
          $('.p-userWayFunctions__itemBox').owlCarousel({
            loop:false,
            margin: 0,
            responsiveClass:true,
            items:1,
            nav:true,
            dots: true,
            loop:true,
            navText: ['<i class="c-icon c-carousel--prev"></i>', '<i class="c-icon c-carousel--next"></i>']
          });

          $(document).on('click', '.footer__col .c-h4', function () {
                if ($(this).next('ul').length) {
                  $(this).toggleClass('open');
                  $(this).next('ul').toggleClass('open');
                }
          });
          $(document).on('click', '.accordion-button', function () {
            $(this).toggleClass('open');
            $($(this).data('target')).toggleClass('open');
          });
          $(document).on('click', '.btn--menu', function () {
            $(this).toggleClass('open');
            $($(this).data('target')).toggleClass('open');
          });
          $(document).on('change', '.c-filter--year select', function () {
            $(this).closest('form').submit();

          });
          $('.naviList > li, .c-navi__logo, .c-navi__menu__extend >.btn--menu, .c-navi__menu__extend > a ').hover( function () {
            if ($(this).hasClass('js--hover')) {
              if(!$(this).closest('.c-navi').hasClass('hover')) {
                $(this).closest('.c-navi').addClass('hover');
              }
            }else {
              $(this).closest('.c-navi').removeClass('hover');
            }
          }, function() {
            $('.c-navi').removeClass('hover');
          });
          var header = $('.c-navi');
          var banner = $('.banner');
          var sticky = 60;
          window.onscroll = function() {
            if (window.pageYOffset > sticky) {
              header.addClass("sticky");
            } else {
              header.removeClass("sticky");
            }
          }
          $(document).on('click', '.c-tab a', function () {
           $('.c-panel').find('.c-panel__content').removeClass('active');
           $(this).closest('.c-tabs').find('.c-tab').removeClass('active');
           $(this).closest('.c-tab').addClass('active');
           $($(this).data('target')).addClass('active')

          });
          $(document).on('click', '.open__inquiries', function () {
              $('#InquiriesModal').show();
          });
          $(document).on('click', '.close__inquiries', function () {
            $('#InquiriesModal').hide();
        });
          $.fn.inputFilter = function(callback, errMsg) {
            return this.on("input keydown keyup mousedown mouseup select contextmenu drop focusout", function(e) {
              if (callback(this.value)) {
                // Accepted value
                if (["keydown","mousedown","focusout"].indexOf(e.type) >= 0){
                  $(this).removeClass("input-error");
                  this.setCustomValidity("");
                }
                this.oldValue = this.value;
                this.oldSelectionStart = this.selectionStart;
                this.oldSelectionEnd = this.selectionEnd;
              } else if (this.hasOwnProperty("oldValue")) {
                // Rejected value - restore the previous one
                $(this).addClass("input-error");
                this.setCustomValidity(errMsg);
                this.reportValidity();
                this.value = this.oldValue;
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
              } else {
                // Rejected value - nothing to restore
                this.value = "";
              }
            });
          };

          $(".phone__number").inputFilter(function(value) {
            return /^\d*$/.test(value);    // Allow digits only, using a RegExp
          },"数字のみが許可されています");

          $(document).on('click', '.media__item__info, .l-actions', function () {
            window.location.href = $(this).find('a').first().attr('href');
          });

          $('.partner__logo1, .partner__logo3').slick({
            autoplay: true,
            //自動でスクロール
             autoplaySpeed: 100,
             //自動再生のスライド切り替えまでの時間を設定
             speed: 3000,
             //スライドが流れる速度を設定
             cssEase: "linear",
             //スライドの流れ方を等速に設定
             slidesToShow: 1,
             //表示するスライドの数
             swipe: false,
             // 操作による切り替えはさせない
             arrows: false,
             //矢印非表示
             pauseOnFocus: false,
             //スライダーをフォーカスした時にスライドを停止させるか
             pauseOnHover: false,
             //スライダーにマウスホバーした時にスライドを停止させるか
             variableWidth: true,
             swipeToSlide: false,
             touchMove: false,
             });

            $('.partner__logo2').slick({
                autoplay: true,
                //自動でスクロール
                autoplaySpeed: 100,
                //自動再生のスライド切り替えまでの時間を設定
                speed: 3000,
                //スライドが流れる速度を設定
                cssEase: "linear",
                //スライドの流れ方を等速に設定
                slidesToShow: 1,
                //表示するスライドの数
                swipe: false,
                // 操作による切り替えはさせない
                arrows: false,
                //矢印非表示
                pauseOnFocus: false,
                //スライダーをフォーカスした時にスライドを停止させるか
                pauseOnHover: false,
                //スライダーにマウスホバーした時にスライドを停止させるか
                variableWidth: true,
                swipeToSlide: false,
                touchMove: false,
                rtl: true
              });

        });
      }
  }();
});
})(typeof define === 'function' && define.amd ? define : function (deps, factory) {
if (typeof module !== 'undefined' && module.exports) {
  //Node
  module.exports = factory(require('jquery'));
} else {
  window.services = factory(window.jQuery);
}
});

